import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ComingSoon from './pages/ComingSoon';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<ComingSoon />);

reportWebVitals();
